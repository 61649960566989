
export default {
    namespaced: true,
    state: {
        timeZoneList: []        
    },
    mutations: {
        clearData(state) {
            state.timeZoneList = [];            
        },        
        setTimeZoneList: function (state, { timeZoneList }) {
            timeZoneList.forEach(function (item) {
                state.timeZoneList.push(item.timeZoneCdName); 
            });
                                           
        }
    }
}